exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consulting-index-js": () => import("./../../../src/pages/consulting/index.js" /* webpackChunkName: "component---src-pages-consulting-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partner-contentful-categories-slug-js": () => import("./../../../src/pages/partner/{ContentfulCategories.slug}.js" /* webpackChunkName: "component---src-pages-partner-contentful-categories-slug-js" */),
  "component---src-pages-partner-index-js": () => import("./../../../src/pages/partner/index.js" /* webpackChunkName: "component---src-pages-partner-index-js" */),
  "component---src-pages-podcast-index-js": () => import("./../../../src/pages/podcast/index.js" /* webpackChunkName: "component---src-pages-podcast-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-papers-js": () => import("./../../../src/templates/papers.js" /* webpackChunkName: "component---src-templates-papers-js" */),
  "component---src-templates-partner-page-js": () => import("./../../../src/templates/partner-page.js" /* webpackChunkName: "component---src-templates-partner-page-js" */),
  "component---src-templates-podcast-episode-js": () => import("./../../../src/templates/podcast-episode.js" /* webpackChunkName: "component---src-templates-podcast-episode-js" */),
  "component---src-templates-podcast-episodes-js": () => import("./../../../src/templates/podcast-episodes.js" /* webpackChunkName: "component---src-templates-podcast-episodes-js" */)
}

